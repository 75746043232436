import React, { Fragment } from 'react';
import mediaquery from 'mediaquery';
import { Helmet } from 'react-helmet-async';
import imageResizer from '../../helpers/imageResizer';

type Props = {
  imageKey: string;
  images: Array<any>;
  className?: string;
  isLCP: boolean;
};

const ImageResizer = ({
  imageKey,
  images,
  className,
  isLCP = false
}: Props) => {
  const breakpoints = images.map((image) => image.breakpointMaxWidth);
  const queries = mediaquery.asArray(breakpoints);

  const data = images.map((image, index) => {
    const imageData = imageResizer(imageKey, image);

    return {
      ...imageData,
      breakpoint: queries[index][1]
    };
  });

  return (
    <>
      {isLCP && (
        <Helmet>
          {data.map((image) => {
            const { src, breakpoint } = image;

            return (
              <link
                rel="preload"
                as="image"
                href={src}
                media={breakpoint}
                key={`${src}-${breakpoint}`}
              />
            );
          })}
        </Helmet>
      )}

      <picture className={className}>
        {data.map((image, index) => {
          const { src, width, height, breakpoint } = image;

          return (
            <Fragment key={index}>
              <source
                media={breakpoint}
                srcSet={src}
                width={width}
                height={height}
              />

              {images.length - 1 === index && (
                <img
                  src={src}
                  alt="Landing page"
                  width={width}
                  height={height}
                  decoding="async"
                />
              )}
            </Fragment>
          );
        })}
      </picture>
    </>
  );
};

export default ImageResizer;
