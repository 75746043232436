// @ts-nocheck
import useIsClient from "./useIsClient";

// Checks if screen support touch events
// TODO: Might want to use useMatchMediaMatches instead.
const useShouldEnableNative = (mediaQuery = null) => {
  const isClient = useIsClient();

  // No window object, component should render null
  if (!isClient) {
    return true;
  }
  return !!(
    ("ontouchstart" in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch)) &&
    window.matchMedia(mediaQuery || "all").matches
  );
};
export default useShouldEnableNative;
