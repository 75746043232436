// @ts-nocheck
const isAdditionalDot = (totalCount, activeIndex, dotIndex) => {
  // Calculate the maximum number of visible dots
  const maxVisibleDots = 7;
  const maxVisibleAdditionalDots = 2;
  const maxInViewDots = maxVisibleDots - maxVisibleAdditionalDots;

  // Calculate the number of additional slides
  const additionalSlides = totalCount - maxInViewDots;

  // Calculate the start index for displaying dots
  const startIndex =
    activeIndex <= Math.floor(maxInViewDots / 2)
      ? 0
      : Math.min(activeIndex - Math.floor(maxInViewDots / 2), additionalSlides);

  // Calculate the index of the last visible dot
  const endIndex = Math.min(startIndex + maxInViewDots - 1, totalCount - 1);

  // Check if the dot index falls outside the range of visible dots
  return dotIndex < startIndex || dotIndex > endIndex;
};
export default isAdditionalDot;
