// @ts-nocheck
import React, { useEffect, useRef } from "react";
import fillArray from "@justpark/helpers/src/fillArray/fillArray";
import styles from "./paginationDots.module.scss";
import isAdditionalDot from "./isAdditionalDot";

type Props = {
  count: number;
  active: number;
  theme?: "default" | "brand";
  size?: "small" | "large";
  onClick?: null | ((a: number) => void);
};
type DotProps = {
  theme: "default" | "brand";
  size: "small" | "large";
  index: number;
  onClick: null | ((a: number) => void);
  count: number;
  active: number;
};
const Dot = ({ theme, size, onClick, index, count, active }: DotProps) => {
  const clickable = onClick !== null;
  const isAdditional = isAdditionalDot(count, active, index);
  const isActive = index === active;
  const activeClass = isActive ? styles.activeDot : styles.dot;
  const themeClass =
    theme === "brand" ? styles.brandTheme : styles.defaultTheme;
  const sizeClass = size === "small" ? styles.sizeSmall : styles.sizeLarge;
  const isAdditionalDots =
    isAdditional && !clickable ? styles.additionalDot : "";
  const item = (
    <div
      className={`${activeClass} ${themeClass} ${sizeClass} ${isAdditionalDots}`}
    />
  );
  return clickable ? (
    <button
      type="button"
      onClick={() => {
        onClick(index);
      }}
      className={styles.button}
    >
      {item}
    </button>
  ) : (
    <div className={styles.itemWrapper}>{item}</div>
  );
};
const PaginationDots = ({
  count,
  active,
  theme = "default",
  size = "small",
  onClick = null
}: Props) => {
  const ref = useRef();
  const sizeClass = size === "small" ? styles.sizeSmall : styles.sizeLarge;
  useEffect(() => {
    if (active) {
      ref.current?.scrollTo({
        behaviour: "smooth",
        left: active > 3 ? (active - 3) * 20 : 0
      });
    }
  }, [active, ref]);
  return (
    <div className={`${styles.container} ${sizeClass}`} ref={ref}>
      {fillArray(count, (index) => (
        <Dot
          key={index}
          index={index}
          theme={theme}
          size={size}
          onClick={onClick}
          count={count}
          active={active}
        />
      ))}
    </div>
  );
};
export default PaginationDots;
