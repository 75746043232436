import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link } from '@not-remix-run/react';
import ContentCarousel from '@justpark/ui/src/components/ContentCarousel';
import Typography from '@justpark/ui/src/components/Typography';

import styles from '../../../static/scss/components/landing/reviews.module.scss';
import TrustpilotWidget from '../Widgets/TrustPilotWidget';

type Props = {
  reviews: Array<any>;
};

const TrustPilotReviews = ({ reviews }: Props) => (
  <div className={styles.base}>
    <div className={styles.image}>
      <TrustpilotWidget zIndex={-1} height="95px" />
    </div>

    <div className={styles.reviews}>
      <ContentCarousel
        autoscroll={false}
        items={useMemo(
          () =>
            reviews.map(({ comment, sender, location }) => (
              <Link key={sender} to={location.slug}>
                <div data-cy="review-item" className={styles.reviewItem}>
                  <div className={styles.location}>
                    <Trans i18nKey="homePage:sender">
                      <Typography size="subtext">
                        <Typography bold>{{ sender }}</Typography> JustParked at{' '}
                        <Typography color="muted">
                          {{ title: location.title }}{' '}
                        </Typography>
                      </Typography>
                    </Trans>
                  </div>

                  <Typography size="subtext">
                    <q>{comment}</q>
                  </Typography>
                </div>
              </Link>
            )),
          [reviews]
        )}
      />
    </div>
  </div>
);

export default TrustPilotReviews;
