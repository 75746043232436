// @ts-nocheck
import { useEffect, useState, useCallback } from "react";

const useMatchMediaMatches = (query: string): boolean | undefined => {
  const [value, setValue] = useState(undefined);
  const setValueCallback = useCallback(
    (e) => {
      setValue(e.matches);
    },
    [setValue]
  );
  useEffect(() => {
    const mediaQueryList = matchMedia(query);
    setValueCallback(mediaQueryList);
    const eventListenerSupport = "addEventListener" in mediaQueryList;
    if (!eventListenerSupport) {
      return () => {};
    }
    mediaQueryList.addEventListener("change", setValueCallback);
    return () => {
      mediaQueryList.removeEventListener("change", setValueCallback);
    };
  }, [query, setValueCallback]);
  return value;
};
export default useMatchMediaMatches;
