import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from '@not-remix-run/react';
import { useSelector } from 'react-redux';
import SearchTrust from '@justpark/ui/src/components/SearchTrust';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import SearchForm from '@justpark/ui/src/components/SearchForm';
import getDefaultBookingType from '@justpark/helpers/src/getDefaultBookingType/getDefaultBookingType';
import Button from '@justpark/ui/src/components/Button';
import localDateTimeIso8601 from '@justpark/helpers/src/localDateTimeIso8601/localDateTimeIso8601';
import {
  defaultAirportSearchDates,
  defaultSearchDates
} from '../../helpers/date';
import { gtagTrack } from '../../helpers/Tracking/Analytics';
import { selectLanguage } from '../../stores/partner';
import type { T } from '../../types';
import useSetDefaultDates from '../Common/SearchForm/Hooks/useSetDefaultDates';
import useUpdateRecentSearches from '../Common/SearchForm/Hooks/useUpdateRecentSearches';
import ConnectedFavouriteSearches from '../Common/SearchForm/ConnectedFavouritesSearch';
import useGeoLocationChange from '../Common/SearchForm/Hooks/useGeoLocationChange';
import buildSearchQueryParams from '../Common/SearchForm/Helpers/buildSearchQueryParams';
import airports from './airports';
import { ampli } from '../../helpers/Tracking/amplitude';
import useDarklyJSON from '../../hooks/useDarklyJSON';

import styles from './searchFormModule.module.scss';

dayjs.extend(dayjsDuration);
type Props = {
  searchInputRef: any;
  defaultLocation?: {
    lat: number;
    lng: number;
  };
  label: string | T;
  placeholder: string | T;
  submitText?: string;
  timezone: string;
  isDriveup?: boolean;
  showFamiliarSpaces?: boolean;
  searchType?: string;
  showAirports?: boolean;
};

const cypress = 'search-form';
const SearchFormModule = ({
  searchInputRef,
  defaultLocation,
  label,
  placeholder,
  submitText,
  timezone,
  isDriveup,
  showFamiliarSpaces = false,
  searchType = '',
  showAirports = false
}: Props) => {
  const locale = useSelector(selectLanguage);
  const { t } = useTranslation();

  const experimentJSON = useDarklyJSON('airport-search-durations');

  const duration = experimentJSON?.duration ?? 2;
  const lead = experimentJSON?.lead ?? 1;

  const defaultDates = defaultSearchDates(timezone, locale);
  const airportDefaultDates = defaultAirportSearchDates(
    timezone,
    locale,
    duration,
    lead
  );

  const isAirportPage = searchType === 'AIRPORT';

  const [searchParams] = useSearchParams();
  const monthlyParam = searchParams?.get('monthly');
  const isMonthlyDefault =
    isAirportPage === false &&
    (monthlyParam === 'STANDARD' || monthlyParam === 'WEEKDAYS_ONLY');

  const isAirportDefault = searchParams.has('airport');

  const defaultArriving = isAirportPage
    ? airportDefaultDates.arriving
    : defaultDates.arriving;
  const defaultLeaving = isAirportPage
    ? airportDefaultDates.leaving
    : defaultDates.leaving;

  const [searchData, setSearchData] = useState({
    query: '',
    bookingType: getDefaultBookingType(
      isMonthlyDefault,
      isAirportDefault && showAirports
    ),
    arriving: defaultArriving.format(localDateTimeIso8601),
    leaving: defaultLeaving.format(localDateTimeIso8601),
    monthlyStarting: dayjs(defaultDates.arriving)
      .add(2, 'days')
      .format(localDateTimeIso8601),
    monthlyDuration: monthlyParam === 'STANDARD' ? 'STANDARD' : 'WEEKDAYS_ONLY',
    airportArriving: airportDefaultDates.arriving.format(localDateTimeIso8601),
    airportLeaving: airportDefaultDates.leaving.format(localDateTimeIso8601),
    searchData: defaultLocation || {},
    airport: ''
  });

  const navigate = useNavigate();

  const [showValidation, setShowValidation] = useState(false);

  const isAirport = searchData.bookingType === 'airport';

  const selectedAirport = isAirport
    ? Object.values(airports).find((item) => item.value === searchData.airport)
    : undefined;

  const coords = useMemo(() => {
    const {
      searchData: { lat, lng }
    } = searchData;

    if (lat && lng) {
      return {
        lat,
        lng
      };
    }

    return null;
  }, [searchData]);

  const filterQueryString = useMemo(() => {
    if (searchData.bookingType === 'airport') {
      return 'reservable';
    }

    const { arriving } = searchData;
    const arrivingDate = dayjs(arriving);
    const leadTime = dayjs.duration(arrivingDate.diff(dayjs())).asMinutes();
    return leadTime > 30 ? 'reservable' : '';
  }, [searchData]);

  const {
    arriving,
    leaving,
    airportArriving,
    airportLeaving,
    monthlyStarting,
    monthlyDuration
  } = searchData;

  const isValid = useMemo(() => {
    const {
      searchData: { lat, lng }
    } = searchData;

    const standardSearchValid = defaultLocation !== undefined || (lat && lng);
    const airportSearchValid = selectedAirport !== undefined;

    return isAirport ? airportSearchValid : standardSearchValid;
  }, [searchData, defaultLocation, selectedAirport, isAirport]);

  const getSearchParams = useCallback(() => {
    const arrivingValue = isAirport ? airportArriving : arriving;
    const leavingValue = isAirport ? airportLeaving : leaving;
    const queryValue = isAirport ? selectedAirport.label : searchData.query;
    const coordsValue = isAirport
      ? selectedAirport.coords
      : searchData.searchData;

    return {
      arriving: arrivingValue,
      leaving: leavingValue,
      query: queryValue || placeholder,
      coords: coordsValue,
      googlePlaceId: isAirport ? undefined : searchData.searchData.googlePlaceId
    };
  }, [
    airportArriving,
    airportLeaving,
    arriving,
    isAirport,
    leaving,
    placeholder,
    searchData,
    selectedAirport
  ]);

  const onSubmit = () => {
    if (!isValid) {
      return setShowValidation(true);
    }

    const {
      arriving: arrivingValue,
      leaving: leavingValue,
      query,
      coords: coordsValue,
      googlePlaceId
    } = getSearchParams();

    const params = {
      query,
      arriving:
        arrivingValue || defaultDates.arriving.format(localDateTimeIso8601),
      leaving:
        leavingValue || defaultDates.leaving.format(localDateTimeIso8601),
      coords: coordsValue,
      monthlyDuration,
      monthlyStarting:
        monthlyStarting ||
        defaultDates.arriving.startOf('day').format(localDateTimeIso8601),
      bookingType: isAirportPage ? 'airport' : searchData.bookingType,
      filters: filterQueryString,
      googlePlaceId,
      showAirportOnboarding:
        isAirportPage || searchData.bookingType === 'airport'
    };

    const queryParams = buildSearchQueryParams(params);

    const isMonthlyBooking = searchData.bookingType === 'monthly';
    gtagTrack('core:search-block:clicked-search', { isMonthlyBooking });

    const getSearchType = () => {
      const { bookingType } = params;

      if (bookingType === 'airport' || bookingType === 'hourly') {
        return bookingType;
      }

      if (monthlyDuration === 'WEEKDAYS_ONLY') {
        return 'monthly_weekdays';
      }

      if (monthlyDuration === 'STANDARD') {
        return 'monthly_all';
      }

      return undefined;
    };

    ampli.searchFormSubmitted({
      search_query: query,
      search_type: getSearchType()
    });

    setShowValidation(false);
    return navigate({ pathname: '/search', search: queryParams });
  };

  const driveupListingId = searchData.searchData?.listingId;
  const payOnExit = searchData.searchData?.payOnExit;
  const slug = searchData.searchData?.slug;

  useEffect(() => {
    if (driveupListingId && payOnExit) {
      navigate(slug);
    }

    if (driveupListingId && !payOnExit) {
      navigate({
        pathname: `/checkout/${driveupListingId}`
      });
    }
  }, [driveupListingId, navigate, payOnExit, slug]);

  useEffect(() => {
    setShowValidation(false);
  }, [searchData.bookingType]);

  const setDefaultHourlyDates = useSetDefaultDates(setSearchData, {
    arriving: defaultSearchDates().arriving.format(localDateTimeIso8601),
    leaving: defaultSearchDates().leaving.format(localDateTimeIso8601)
  });

  const setDefaultMonthlyDates = useSetDefaultDates(setSearchData, {
    monthlyStarting: defaultSearchDates()
      .arriving.startOf('day')
      .format(localDateTimeIso8601)
  });

  const placeholderText = isDriveup
    ? t('searchForm:driveupPlaceholder', 'Enter location ID')
    : placeholder;

  const geoLocationChange = useGeoLocationChange();
  const onGooglePlaceChange = useUpdateRecentSearches(showFamiliarSpaces, 5);

  return (
    <div className={styles.base}>
      <SearchForm
        onChange={setSearchData}
        query={searchData.query}
        coords={coords}
        arriving={searchData.arriving}
        leaving={searchData.leaving}
        airportArriving={searchData.airportArriving}
        airportLeaving={searchData.airportLeaving}
        monthlyStarting={searchData.monthlyStarting}
        monthlyDuration={searchData.monthlyDuration}
        bookingType={searchData.bookingType}
        searchInputRef={searchInputRef}
        handleOnGooglePlaceChange={onGooglePlaceChange}
        geoLocationChange={geoLocationChange}
        showValidation={showValidation}
        setDefaultHourlyDates={setDefaultHourlyDates}
        setDefaultMonthlyDates={setDefaultMonthlyDates}
        label={label}
        placeholder={placeholderText}
        locationBias={defaultLocation}
        resultsSlotComponent={
          showFamiliarSpaces && (
            <ConnectedFavouriteSearches onSearchDataChange={setSearchData} />
          )
        }
        isMonthlyDefault={isMonthlyDefault}
        isAirportDefault={isAirportDefault}
        t={t}
        showAirport={showAirports}
        airports={airports}
        selectedAirport={searchData.airport}
        hideTabs={isAirportPage}
      />

      <div className={styles.trust}>
        <SearchTrust
          leftText={t('search:trustSpaces', 'Spaces verified')}
          centralText={t('search:trustCancel', 'Easy to cancel')}
          rightText={t('search:trustExtend', 'Easy to extend')}
        />
      </div>

      <Button
        className={styles.submitButton}
        onClick={onSubmit}
        cypress={`${cypress}-button`}
      >
        {submitText ||
          t('searchForm:submitText', 'Show me the best parking deals')}
      </Button>
    </div>
  );
};

export default SearchFormModule;
