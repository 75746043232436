import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@justpark/ui/src/components/Typography';

import styles from './threeTrustBanner.module.scss';

const LandingTrustBanner = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.base}>
      <div className={styles.item}>
        <Typography size="secondary" className={styles.text} bold>
          {t('trust:bestPrice', 'Best price guarantee')}
        </Typography>
      </div>

      <div className={styles.item}>
        <Typography size="secondary" className={styles.text} bold>
          {t('trust:driverTrust', 'Trusted by 13m+ drivers')}
        </Typography>
      </div>

      <div className={styles.item}>
        <Typography size="secondary" className={styles.text} bold>
          {t('trust:spaceCount', '100k+ reservable spaces')}
        </Typography>
      </div>
    </div>
  );
};

export default LandingTrustBanner;
