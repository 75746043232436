// @ts-nocheck
import { useEffect } from "react";

const useIsIntersectingWithRoot = (
  elRef,
  rootRef,
  threshold,
  onIntersecting,
  onNoLongerIntersecting
) => {
  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      return () => {};
    }
    if (!elRef.current) {
      return () => {};
    }
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersecting();
          }
          if (!entry.isIntersecting && !!onNoLongerIntersecting) {
            onNoLongerIntersecting();
          }
        });
      },
      {
        root: rootRef.current,
        threshold
      }
    );
    observer.observe(elRef.current);
    return () => {
      observer.disconnect();
    };
  }, [rootRef, threshold, onIntersecting, elRef, onNoLongerIntersecting]);
};
export default useIsIntersectingWithRoot;
