// @ts-nocheck
import React from "react";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import FontAwesomeIcon from "../FontAwesomeIcon";
import getSizeClass from "../../../helpers/getFontAwesomeSizeClassname";

type Props = {
  size?: 16 | 24 | 32 | 40;
  className: string;
};
const Check = ({ size, className }: Props) => {
  const sizeClass = getSizeClass(size);
  return (
    <div className={`${sizeClass} ${className}`}>
      <FontAwesomeIcon icon={faCheck} className={sizeClass} />
    </div>
  );
};
export default Check;
