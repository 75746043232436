import { useCallback } from 'react';

const useSetDefaultDates = (
  setSearchData,
  { arriving, leaving, monthlyStarting }
) =>
  useCallback(() => {
    setSearchData((existingData) => ({
      ...existingData,
      ...(arriving && arriving),
      ...(leaving && leaving),
      ...(monthlyStarting && monthlyStarting)
    }));
  }, [arriving, leaving, monthlyStarting, setSearchData]);

export default useSetDefaultDates;
