/**
 * @typedef {Object} resizedImage
 * @property {string} src - Image src
 * @property {number} width - Image width
 * @property {number} height - Image height
 * @property {number} breakpoint - Breakpoint to render the image
 */

/**
 *
 * @param {string} key The name of an original image file (with file extension)
 * @param {object} image Information about the image
 * @param {number} [image.width] Image width
 * @param {number} [image.height] Image height
 * @param {('cover', 'contain', 'fill', 'inside', 'outside')} [image.fit] Image fit
 * @param {object} [image.filters] Additional filters for the image
 * @param {number} [image.breakpoint] Breakpoint to render the image
 * @return {resizedImage}
 */
const imageResizer = (key, image) => {
  const {
    width,
    height,
    fit = 'cover',
    filters,
    breakpoint
  } = image;
  const body = {
    bucket: import.meta.env.IMAGE_RESIZER_BUCKET,
    key,
    edits: {
      resize: {
        width,
        height,
        fit
      },
      ...filters,
      jpeg: {
        quality: 70
      },
      webp: {
        quality: 70
      }
    }
  };
  const stringifyBody = JSON.stringify(body);
  const host = import.meta.env.IMAGE_RESIZER_URL;
  const src = `${host}/${btoa(stringifyBody)}`;
  return {
    src,
    width,
    height,
    breakpoint: breakpoint || width
  };
};
export default imageResizer;