import useDarkly from './useDarkly';

const useDarklyJSON = (id) => {
  const value = useDarkly(id);

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    // If we get "empty" JSON back then return undefined which would be the same as if the flag was not set in redux
    return undefined;
  }

  return value;
};

export default useDarklyJSON;
