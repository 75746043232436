// @ts-nocheck
import { useEffect, useState } from "react";

// Checks if isClient
const useIsClient = () => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return isClient;
};
export default useIsClient;
