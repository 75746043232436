// @ts-nocheck
import React, { Children } from "react";
import styles from "./contentCarousel.module.scss";

type Props = {
  items: Array<any>;
  autoscroll?: boolean;
};
const ContentCarousel = ({ items, autoscroll = true }: Props) => {
  const itemCount = items.length;
  return (
    <div
      className={autoscroll ? styles.base : styles.baseNoScroll}
      style={{
        "--count": itemCount
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.itemWrapper}>
          {Children.map(items, (item) => (
            <div className={styles.item}>{item}</div>
          ))}
        </div>
        {autoscroll && (
          <div className={styles.itemWrapper} aria-hidden>
            {Children.map(items, (item) => (
              <div className={styles.item}>{item}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default ContentCarousel;
