// @ts-nocheck
import React from "react";
import Check from "../FontAwesome/Check";
import styles from "./searchTrust.module.scss";
import Typography from "../Typography";

type Props = {
  className?: string;
  leftText: string;
  centralText: string;
  rightText: string;
};
const SearchTrust = ({
  className = "",
  leftText,
  centralText,
  rightText
}: Props) => {
  return (
    <ul className={`${styles.base} ${className}`}>
      <li>
        <Check size={16} className={styles.check} />
        <Typography size="secondary" color="low-emphasis">
          {leftText}
        </Typography>
      </li>
      <li>
        <Check size={16} className={styles.check} />
        <Typography size="secondary" color="low-emphasis">
          {centralText}
        </Typography>
      </li>
      <li>
        <Check size={16} className={styles.check} />
        <Typography size="secondary" color="low-emphasis">
          {rightText}
        </Typography>
      </li>
    </ul>
  );
};
export default SearchTrust;
