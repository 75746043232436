import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@not-remix-run/react';
import BrandTypography from '@justpark/ui/src/components/BrandTypography';
import Typography from '@justpark/ui/src/components/Typography';
import Button from '@justpark/ui/src/components/Button';
import LocationSearchInput from '@justpark/ui/src/components/LocationSearchInput';
import useMatchMediaMatches from '@justpark/ui/src/hooks/useMatchMediaMatches';
import ImageResizer from './Common/ImageResizer';
import SearchFormModule from './Landing/SearchFormModule';
import CmsContent from './CmsContent';
import type { T } from '../types';
import { ampli } from '../helpers/Tracking/amplitude';

import styles from '../../static/scss/components/searchHero.module.scss';
import LandingTrustBanner from './LandingTrustBanner';

const heroImage = [
  {
    width: 2 * 320,
    height: 2 * 225,
    breakpointMaxWidth: 374
  },
  {
    width: 2 * 375,
    height: 2 * 265,
    breakpointMaxWidth: 381
  },
  {
    width: 2 * 390,
    height: 2 * 442,
    breakpointMaxWidth: 424
  },
  {
    width: 2 * 647,
    height: 2 * 625,
    breakpointMaxWidth: 859
  },
  {
    width: 2 * 540,
    height: 2 * 540,
    breakpointMaxWidth: 1023
  },
  {
    width: 2 * 625,
    height: 2 * 625
  }
];
type Props = {
  name?: string;
  slotComponent?: any;
  timezone: string;
  heroImageUrl: string;
  title: string;
  body: string;
  centerPoint: { lat: number; lng: number } | undefined;
  locationLabel: T;
  locationPlaceholder: T;
  submitText?: T;
  showDriveupOnlyForm: boolean;
  showFamiliarSpaces?: boolean;
  searchType?: string;
  showAirports?: boolean;
};

const SearchHero = ({
  name,
  slotComponent,
  timezone,
  heroImageUrl,
  title,
  body,
  centerPoint,
  locationLabel,
  locationPlaceholder,
  submitText,
  showDriveupOnlyForm,
  showFamiliarSpaces,
  searchType = '',
  showAirports = false
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDriveUpForm, setShowDriveUpForm] = useState(false);
  const searchFormRef = useRef(null);
  const searchDriveupFormRef = useRef(null);
  const searchInputRef = useRef(null);

  const isMobileLayout = useMatchMediaMatches('(max-width: 639px)');

  const onFindClick = () => searchFormRef.current.scrollIntoView();

  const onCloseDriveupForm = () => setShowDriveUpForm(false);

  const onDriveupLocationChange = (value, searchData) => {
    const { listingId, payOnExit, slug } = searchData;

    if (listingId && payOnExit) {
      navigate(slug);
    }

    if (listingId && !payOnExit) {
      navigate({
        pathname: `/checkout/${listingId}`
      });
    }
  };

  const driveupSearchOpen = showDriveUpForm && isMobileLayout;

  useEffect(() => {
    if (driveupSearchOpen) {
      ampli.locationIdSearchStarted();
    }
  }, [driveupSearchOpen]);

  return (
    <div className={styles.searchHero}>
      <div className={styles.contentImageWrapperPadding}>
        <div className={styles.contentImageWrapper}>
          <div className={styles.trustContainer}>
            <LandingTrustBanner />
          </div>

          <div className={styles.imageContainer}>
            <ImageResizer
              imageKey={heroImageUrl}
              images={heroImage}
              className={styles.image}
              isLCP
            />
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.contentWrapper}>
              <div className={styles.headerContainer}>
                <BrandTypography level={1} className={styles.heroHeading} bold>
                  {typeof title === 'string' ? (
                    <CmsContent type="search hero title" content={title} />
                  ) : (
                    title
                  )}
                </BrandTypography>
              </div>
              <div className={styles.content}>
                <div className={styles.slotComponent}>
                  {!!slotComponent && slotComponent}
                </div>

                <Typography>
                  <p>{body}</p>
                </Typography>

                {showDriveupOnlyForm && (
                  <Button
                    onClick={() => setShowDriveUpForm(true)}
                    className={styles.findDriveupButton}
                    cypress="driveup-form"
                    modifier="primary"
                    outline
                  >
                    {t('searchForm:parkedPayNow', 'Parked? Pay now')}
                  </Button>
                )}

                <Button
                  onClick={onFindClick}
                  className={styles.findParkingButton}
                >
                  {t('searchForm:findParking', 'Find parking')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {driveupSearchOpen && (
        <LocationSearchInput
          value=""
          searchInputRef={searchDriveupFormRef}
          invalid={false}
          onChange={onDriveupLocationChange}
          onCancelClick={onCloseDriveupForm}
          showDriveupOnlyForm
          t={t}
        />
      )}

      <div className={styles.searchForm} ref={searchFormRef}>
        <SearchFormModule
          searchInputRef={searchInputRef}
          defaultLocation={centerPoint}
          label={locationLabel}
          placeholder={name || locationPlaceholder}
          submitText={submitText}
          timezone={timezone}
          showFamiliarSpaces={showFamiliarSpaces}
          searchType={searchType}
          showAirports={showAirports}
        />
      </div>
    </div>
  );
};

export default SearchHero;
