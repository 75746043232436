// @ts-nocheck
import { useEffect } from "react";

const useModalHook = (isOpen: boolean, className: string) => {
  /**
   * https://stackoverflow.com/questions/9280258/prevent-body-scrolling-but-allow-overlay-scrolling/45230674#45230674
   *
   * The below only runs where is can find a #content element, which is the JP Web App wrapping element
   * It will add a class to the body, which will cause CSS in JP Web to be applied to the content which adds positon fixed in order to disable body scrolling on iOS
   * It also uses JS to get the current scroll position and apply it using - top in order to retain the visual scroll position
   *
   * Then on unmount is uses scrollTo to restore the scroll position
   *
   * See styles in JP Web for .modal-open #content
   */

  useEffect(() => {
    const support = "classList" in document.body;
    const mainEl = document.getElementById("content");
    if (!support || !isOpen || !mainEl) {
      return () => {};
    }
    const scrollPosition = window.pageYOffset;
    mainEl.style.top = `${-1 * scrollPosition}px`;
    document.body.classList.add(className);
    return () => {
      document.body.classList.remove(className);
      window.scrollTo(0, scrollPosition);
      mainEl.style.top = 0;
    };
  }, [isOpen, className]);
};
export default useModalHook;
